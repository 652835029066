html {
    --color-primary: #34303d;
    --color-secondary: #f6f4f0;
    --color-secondary-a20: rgba(246, 244, 240, 0.2);
    --color-tertiary: #00d1d2;
    --color-tertiary-30: #b2f1f1;
    --color-tertiary-a30: #b2f1f1;
    --color-grey: #c4c4c4;
    --color-alto: #d9d9d9;
    --color-error: #c73636;
    --color-romance: #fffdfb;
    --color-java: #1bd9c5;
    --color-scarpa-flow: #524f5a;
    --color-cararra: #f5f4f2;
    --color-mercury: #e8e8e8;
    --color-jumbo: #7b7881;
    --color-mischka: #e1e0e2;
    --color-twine: #c19766;
    --color-french-gray: #b8b7bb;
    --color-french-grey: #cccace;
    --color-fluorescent-red: #eb0b00;
    --color-silver: #f7f7f7;
    --color-minty: #add0ba;
    --color-sandy: #e5b052;
    --color-primary-tins-slate: 153 151 158; // #99979e
    --color-bright-sun: #ffd040;
    --color-granite-gray: #606568;

    --color-alto-second: #d3d3d3;
    --color-black-a10: rgba(0, 0, 0, 0.1);
    --color-black-a30: rgba(0, 0, 0, 0.3);
    --color-black-a40: rgba(0, 0, 0, 0.4);
    --color-bombay: #afb0b4;
    --color-charade: #272a33;
    --color-cararra: #f5f4f2;
    --color-cod-gray: #111111;
    --color-corn: #e5c100;
    --color-dove-gray-a30: rgba(102, 102, 102, 0.3);
    --color-dove-gray: #666666;
    --color-dusty-gray: #979797;
    --color-iron: #e2e5e6;
    --color-guardsman-red: #cf0000;
    --color-mercury: #e8e8e8;
    --color-robins-egg-blue: #0cc;
    --color-rodeo-dust: #cfb2a0;
    --color-shark: #2d3038;
    --color-storm-gray: #73757c;
    --color-sunglow: #fc3;
    --color-transparent: transparent;
    --color-tuna: #383839;
    --color-tundora: #444444;
    --color-white-a50: rgba(255, 255, 255, 0.5);
    --color-white-a60: rgba(255, 255, 255, 0.6);
    --color-white: white;
    --color-amber: #e27d34;
    --color-we-embrace: #9a7fff;
}
