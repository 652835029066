$fontPath: '../fonts/';

@mixin font-face($family, $fileName, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$fontPath}#{$fileName}.woff2') format('woff2');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@include font-face('Iqos', 'IQOS-Bold', 700);
@include font-face('Iqos', 'IQOS-Regular', 400);
@include font-face('Iqos', 'IQOS-Light', 300);
@include font-face('IBMPlexSans', 'IBMPlexSans-VariableFont_wdth,wght');

#root {
    --ica-font-family: Iqos, Helvetica, Arial, sans-serif;
}
