@import '../../../assets/scss/medias';

.Carousel {
    :global(.swiper-wrapper) {
        height: auto;
    }

    :global(.swiper-slide) {
        width: auto;
        height: inherit !important;
    }

    :global(.swiper-slide:first-child) {
        margin-left: 16px;
    }

    :global(.swiper-slide:last-child) {
        margin-right: 16px;
    }

    @include media_md {
        :global(.swiper-slide:first-child) {
            margin-left: 0;
        }

        :global(.swiper-slide:last-child) {
            margin-right: 0;
        }
    }
}
