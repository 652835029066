@import './variables';
@import './fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './base.scss';
@import './components.scss';
@import './utilities.scss';

.ReactModal__Html--open {
    .page-content {
        display: none !important;
    }
}
