@import '../../../../../../../../assets/scss/medias';

.DeviceButton {
    @include media_lg() {
        &:hover .DeleteContainer {
            --crop-left: 0;
        }

        &:focus .DeleteContainer {
            --crop-left: 0;
        }
    }
}

.DeleteContainer {
    --transition: clip-path 0.5s; // 0.5s for hover, 0.2s for touch
    --container-width: 88px;
    --crop-left: var(--container-width);

    position: absolute;
    right: 0;
    top: -1px;
    height: calc(100% + 2px);
    width: var(--container-width);
    background-color: var(--color-we-embrace);
    color: var(--color-white);
    clip-path: inset(0 0 0 var(--crop-left));
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: var(--transition);
    transition-timing-function: cubic-bezier(0.175, 1.56, 0.865, 1.105);

    &:focus {
        --crop-left: 0;
    }
}
