@import '../../../assets/scss/medias';

.AppPopup {
    &:focus-visible {
        outline: none;
    }
}

.PopupContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 101;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: var(--color-romance);
    padding: 32px;

    @include media_ml {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: min-content;
        padding: 64px 50px;
        max-width: 816px;
        margin-left: calc(var(--sidebar-width) / 2);
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 101;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.4;
    }
}

.OverlayOnPopupClose {
    &:before {
        opacity: 0;
        transition: 700ms ease;
    }
}

.PopupOnClose {
    transform: translateY(150%);
    transition: 700ms ease;
}
