@import '../scss/medias';

div:focus {
    border: none;
}

ul {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: none !important;
}

.aem-container {
    #root {
        min-height: 100% !important;
    }

    .max-h-screen {
        max-height: 100% !important;
    }

    .min-h-screen {
        min-height: 100% !important;
    }

    .h-screen {
        height: 100% !important;
    }

    .min-h-\[calc\(100vh-96px\)\] {
        min-height: calc(100% - 96px) !important;
    }

    .ml\:min-h-\[calc\(100vh-128px\)\] {
        min-height: calc(100% - 128px) !important;
    }

    .min-h-\[calc\(100vh-160px\)\] {
        min-height: calc(100% - 160px) !important;
    }
}
