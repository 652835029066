@import '../../../assets/scss/medias';

.Dots {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    :global(li.slick-active button::before) {
        opacity: 1;
    }

    li {
        width: auto;
        height: auto;
        cursor: pointer;
        display: inline-block !important; // RS: !important is used to override DCS styles

        &:last-child {
            margin-right: 0;
        }

        button {
            font-size: 0;
            line-height: 0;
            padding: 6px;
            display: block;
            cursor: pointer;
            color: transparent;
            border: 0;
            background: transparent;
            border-radius: 100%;

            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                opacity: 0.25;
                background: var(--color-primary);
                border-radius: 100%;
            }
        }
    }
}

.DotsFocus {
    @include media_ml {
        button:hover::before,
        button:focus::before {
            opacity: 0.5;
        }
    }
}
