.PopupSideBar {
    &:before {
        opacity: 0;
        transition: opacity 300ms ease-out;
    }

    &:global(.ReactModal__Overlay--after-open):before {
        opacity: 0.4;
    }

    :global(.ReactModal__Content) {
        transform: translate(100%, 0);
        transition: transform 300ms ease-out;
    }

    :global(.ReactModal__Content--after-open) {
        transform: translate(0, 0);
    }

    &:global(.ReactModal__Overlay--before-close):before {
        opacity: 0;
    }

    :global(.ReactModal__Content--before-close) {
        transform: translate(100%, 0);
    }
}
